import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { themeColors, themeIcon } from "../../../constants/constants";
import api from "../../../services/api";
import { AppDispatch, useAppDispatch } from "../../../store";
import { updateNotificationCounter } from "../../../store/UserReducer";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const NotificationContainer = styled.div`
    border-top: 2px solid ${themeColors.notificationContainerBorder};
    max-height: calc(-120px + 80vh);
    overflow-y: auto;
    margin: 0 25px;
    padding-right: 5px;

    &::-webkit-scrollbar {
        margin-left: 20px;
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: ${themeColors.notificationContainerScrollBackgroundColor};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${themeColors.notificationContainerScrollThumbBackgroundColor};
        border-radius: 3px;
        border: 1px solid ${themeColors.notificationContainerScrollThumbBorder};
    }
`;

const Row = styled.div<{ readed: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 2px solid ${themeColors.notificationListRowBorder};
    background-color: ${(props) =>
        props.readed ? themeColors.notificationListRowColorReaded : themeColors.notificationListRowColorUnReaded};
    cursor: pointer;
`;

const NameOfNotification = styled.div`
    font-size: 16px;
    color: ${themeColors.notificationListColor};
`;

const DateOfNotification = styled.div`
    font-size: 14px;
    margin-right: 20px;
    color: ${themeColors.notificationListColor};
`;

const NoNotification = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 200px;
`;

const SetAllAsViewed = styled.div`
    cursor: pointer;
    position: absolute;
    top: 75px;
    right: 50px;
    font-size: 12px;
`;

interface Notification {
    viewed_at: string;
    message: string;
    created_at: string;
    type: string;
    id: string;
    reference_id: string;
}

export const NotificationList = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [offset, setOffset] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const containerRef = useRef<HTMLDivElement>(null);

    const getNotifications = async () => {
        try {
            const res = await api.get(`api/v1/get-notifications?limit=20&offset=${offset}`);
            const { data, meta } = res.data;

            setNotifications((prevNotifications) => [...prevNotifications, ...data]);
            setHasMore(meta.nextPage);
            setOffset((prevOffset) => prevOffset + data.length);
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };

    const getFirstNotifications = async () => {
        try {
            const res = await api.get(`api/v1/get-notifications?limit=20&offset=0`);
            const { data, meta } = res.data;

            setNotifications(() => [...data]);
            setHasMore(meta.nextPage);
            setOffset((prevOffset) => prevOffset + data.length);
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };

    const getNotificationsCount = async () => {
        const res = await api.get(`api/v1/get-notifications-count`);
        dispatch(updateNotificationCounter(res.data.count));
    };

    useEffect(() => {
        getNotifications();
    }, []);

    const handleNotificationClick = (notification: Notification) => {
        api.post(`api/v1/set-notification-as-viewed?notification_id=${notification.id}`, {});
        setTimeout(() => {
            getNotificationsCount();
        }, 1000);
        if (notification.type === "received_document_from_dms") {
            navigate(`/statusZahteva?brojPredmetaRgz=${notification.reference_id}`);
        }
        if (notification.type === "user_limit_exceeded") {
            navigate(`/upravljanjeKorisnicima?email=${notification.reference_id}`);
        }
    };

    const setAllNotificationsAsViewed = () => {
        api.post(`api/v1/set-all-notification-as-viewed`, {});
        setTimeout(() => {
            getNotificationsCount();
            getFirstNotifications();
        }, 1000);
    };

    const deleteNotification = (notification: Notification) => {
        api.delete(`api/v1/delete-notification?notification_id=${notification.id}`);
        setNotifications((prevNotifications) => prevNotifications.filter((n) => n.id !== notification.id));
    };

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight === scrollHeight && hasMore) {
                getNotifications();
            }
        }
    };

    return (
        <NotificationContainer ref={containerRef} onScroll={handleScroll}>
            <SetAllAsViewed onClick={setAllNotificationsAsViewed}>Означи све као прочитано</SetAllAsViewed>

            {notifications.length > 0 ? (
                notifications.map((notification: Notification, index) => (
                    <Row
                        key={index}
                        readed={!!notification.viewed_at}
                        onClick={() => {
                            handleNotificationClick(notification);
                        }}
                    >
                        <NameOfNotification>{notification.message}</NameOfNotification>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <DateOfNotification>{moment(notification.created_at).format("YYYY-MM-DD HH:mm")}</DateOfNotification>
                            <img
                                src={`${themeIcon}/delete.png`}
                                alt="Delete button"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    deleteNotification(notification);
                                }}
                            />
                        </div>
                    </Row>
                ))
            ) : (
                <NoNotification>
                    <img src={`${themeIcon}/notificationLight.png`} alt="Notification button" />
                    <p style={{ color: themeColors.noNotificationParagraph, fontSize: "14px" }}>Тренутно немате пристиглих нотификација!</p>
                </NoNotification>
            )}
        </NotificationContainer>
    );
};
