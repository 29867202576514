import { FC, useCallback, useEffect, useState } from "react";
import { ModalContent, Row } from "../style";
import KatakomButton from "../../shared/KatakomButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { TabSwitcherLight } from "../../shared/KatakomTabs";
import KatakomTable from "../../shared/KatakomTable";
import { themeColors, themeIcon } from "../../../constants/constants";
import moment from "moment";
import api from "../../../services/api";
import JSZip from "jszip";

interface Props {
    closeModal: () => void;
}

const StatusPreviewModal: FC<Props> = ({ closeModal }) => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const caseStatus = useSelector((state: RootState) => state.caseStatus.caseStatusPreview);
    const [receivedFiles, setReceivedFiles] = useState([]);
    const [sentFiles, setSentFiles] = useState([]);
    const user = useSelector((state: RootState) => state.user);

    const getStatusFiles = useCallback(async () => {
        try {
            const files = await api.get(
                `api/v1/get-files-metadata?idDms=${caseStatus.id_dms}&idPrijemEsalter=${caseStatus.id_prijem_esalter}`
            );

            let remappedReceivedFilesData = files?.data.map((item: any) => {
                if (!item) {
                    return null;
                }
                return {
                    ...item,
                    imePredmeta: caseStatus.ucesnik + " " + moment(caseStatus.datum_predmeta).format("YYYY-MM-DD HH:mm:ss"),
                };
            });

            setReceivedFiles(
                remappedReceivedFilesData
                    .map((item: any) => {
                        if (item.type === "received") {
                            return item;
                        }
                    })
                    .filter(Boolean)
            );
            setSentFiles(
                remappedReceivedFilesData
                    .map((item: any) => {
                        if (item.type === "sent") {
                            return item;
                        }
                    })
                    .filter(Boolean)
            );
        } catch (error) {
            console.error(error);
        }
    }, [caseStatus]);

    useEffect(() => {
        getStatusFiles();
    }, [getStatusFiles]);

    const downloadAllFiles = async (type: string) => {
        try {
            const filesToDownload: any[] = type === "sent" ? sentFiles : type === "received" ? receivedFiles : [];

            const urls: { url: string; fileName: string; fileType: string }[] = [];

            for (const file of filesToDownload) {
                let res;
                if (file.aws_key) {
                    res = await api.get(`api/v1/get-files`, {
                        awsKey: file.aws_key,
                    });
                } else {
                    res = await api.get(`api/v1/get-files`, {
                        idDms: file.id_dms,
                        idDokument: file.dokument_id,
                    });

                    if (res?.status === 200 && res.data.length > 0) {
                        for (const receivedFile of res.data) {
                            downloadReceivedFile({ ...receivedFile, imePredmeta: receivedFile.imePredmeta });
                        }
                    }
                }

                if (res?.status === 200) {
                    urls.push({ url: res.data.url, fileName: file.imePredmeta, fileType: res.data.fileType });
                }
            }

            const fileResponses = await Promise.all(urls.map((file) => fetch(file.url)));
            const fileBlobs = await Promise.all(fileResponses.map((response) => response.blob()));

            for (let i = 0; i < fileBlobs.length; i++) {
                const blob = fileBlobs[i];
                const fileType = urls[i].fileType.toLowerCase();
                const fileName = urls[i].fileName;

                if (fileType === "zip" || fileType === "rar") {
                    handleCompressedFile(blob, fileName, fileType);
                } else {
                    downloadFile(blob, fileName, fileType);
                }
            }
        } catch (error) {
            console.error("Error downloading files:", error);
        }
    };

    const downloadFile = (blob: Blob, fileName: string, fileType: string) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `${fileName}.${fileType}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    };

    const handleCompressedFile = (blob: Blob, fileName: string, fileType: string) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `${fileName}.${fileType}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);

        // Unzip the file on the client side using JSZip
        if (fileType === "zip") {
            const jsZip = new JSZip();
            jsZip
                .loadAsync(blob)
                .then((zip) => {
                    zip.forEach((relativePath, zipEntry) => {
                        zipEntry.async("blob").then((fileBlob) => {
                            const extension = getFileExtension(zipEntry.name);
                            downloadFile(fileBlob, zipEntry.name, extension);
                        });
                    });
                })
                .catch((error) => console.error("Error unzipping file:", error));
        } else {
            // Handle other compressed file types if necessary
            console.warn("Unsupported compressed file type:", fileType);
        }
    };

    const getFileExtension = (fileName: string) => {
        return fileName.split(".").pop() || "";
    };

    const downloadAwsFile = (url: string, fileName: string) => {
        const a = document.createElement("a");
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const ReceivedFiles = (data: any) => {
        if (data.data.length === 0)
            return (
                <div style={{ width: "800px" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "50px",
                        }}
                    >
                        <img style={{ width: "160px", height: "auto" }} src={`${themeIcon}/noFilesIcon.png`} alt="No files"></img>
                        <span style={{ color: themeColors.statusPreviewModalSpan, marginTop: "20px" }}>Нема примљених фајлова!</span>
                    </div>
                </div>
            );

        return (
            <div style={{ width: "800px", margin: "15px" }}>
                <KatakomTable
                    columns={[
                        { label: "pdf", value: "pdf" },
                        { label: "Назив документа", value: "imePredmeta" },
                        {
                            label: "Преузимање",
                            value: "download",
                            func: async (item) => {
                                let res;
                                if (item?.aws_key) {
                                    res = await api.get(`api/v1/get-files`, {
                                        awsKey: item.aws_key,
                                    });

                                    if (res?.status === 200) {
                                        downloadAwsFile(res.data.url, item.imePredmeta);
                                    }
                                } else {
                                    res = await api.get(`api/v1/get-files`, {
                                        idDms: item.id_dms,
                                        idDokument: item.dokument_id,
                                    });

                                    if (res?.status === 200 && res.data.length > 0) {
                                        for (const file of res.data) {
                                            downloadReceivedFile({ ...file, imePredmeta: item.imePredmeta });
                                        }
                                    }
                                }
                            },
                        },
                    ]}
                    data={data.data}
                    withoutHeader={true}
                />

                <Row style={{ justifyContent: "center", margin: "14px 0" }}>
                    <KatakomButton
                        label={"Преузми сва документа"}
                        onClick={() => {
                            downloadAllFiles("received");
                        }}
                        theme="primary"
                        size="xl"
                        style={{ position: "absolute", bottom: "20px" }}
                    />
                </Row>
            </div>
        );
    };

    const SentFiles = (data: any) => {
        if (data.data.length === 0 || data.data[0] === null)
            return (
                <div style={{ width: "800px" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "50px",
                        }}
                    >
                        <img style={{ width: "160px", height: "auto" }} src={`${themeIcon}/noFilesIcon.png`} alt="No files"></img>
                        <span style={{ color: themeColors.statusPreviewModalSpan, marginTop: "20px" }}>Нема послатих фајлова!</span>
                    </div>
                </div>
            );

        return (
            <div style={{ width: "800px", margin: "15px" }}>
                <KatakomTable
                    columns={[
                        { label: "pdf", value: "pdf" },
                        { label: "Назив документа", value: "imePredmeta" },
                        {
                            label: "Преузимање",
                            value: "download",
                            func: async (item) => {
                                let res;
                                if (item?.aws_key) {
                                    res = await api.get(`api/v1/get-files`, {
                                        awsKey: item.aws_key,
                                    });

                                    if (res?.status === 200) {
                                        downloadAwsFile(res.data.url, item.imePredmeta);
                                    }
                                } else {
                                    console.error("Nije moguce preuzeti fajl");
                                }
                            },
                        },
                    ]}
                    data={data.data}
                    withoutHeader={true}
                />

                <Row style={{ justifyContent: "center", margin: "14px 0" }}>
                    <KatakomButton
                        label={"Преузми сва документа"}
                        onClick={() => {
                            downloadAllFiles("sent");
                        }}
                        theme="primary"
                        size="xl"
                        style={{ position: "absolute", bottom: "20px" }}
                    />
                </Row>
            </div>
        );
    };

    return (
        <ModalContent id="AssetModalComponentTip2" tabIndex={0}>
            <Row style={{ margin: "14px 0" }}>
                <TabSwitcherLight
                    tabs={["ПРИМЉЕНА ДОКУМЕНТА", "ПОСЛАТА ДОКУМЕНТА"]}
                    activeTab={activeTab}
                    setActiveTab={(index) => {
                        setActiveTab(index);
                    }}
                >
                    <Row
                        style={{
                            minHeight: "250px",
                            maxHeight: "250px",
                            alignItems: "flex-start",
                            overflowY: "auto",
                            margin: "14px 0",
                        }}
                    >
                        <ReceivedFiles data={receivedFiles} />
                    </Row>
                    <Row
                        style={{
                            minHeight: "250px",
                            maxHeight: "250px",
                            alignItems: "flex-start",
                            overflowY: "auto",
                            margin: "14px 0",
                        }}
                    >
                        <SentFiles data={sentFiles} />
                    </Row>
                </TabSwitcherLight>
                <button
                    style={{
                        color: themeColors.statusPreviewModalButton,
                        position: "absolute",
                        top: "30px",
                        right: "20px",
                        border: "none",
                        outline: "none",
                        background: "transparent",
                        fontSize: "16px",
                        cursor: "pointer",
                    }}
                    onClick={closeModal}
                >
                    Затвори
                </button>
            </Row>
        </ModalContent>
    );
};

const downloadReceivedFile = (file: any) => {
    try {
        const decodedData = atob(file.sadrzaj_dokumenta);
        const byteArray = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; i++) {
            byteArray[i] = decodedData.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: "application/pdf" });

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `${file.imePredmeta}.pdf`;
        document.body.appendChild(a);

        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    } catch (error) {
        console.error("Error downloading the file:", error);
    }
};

export default StatusPreviewModal;
